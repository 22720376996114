




$border-color: $brand-pale-gray;
$background-color: $cool-gray-light;
$hover-background-color: $brand-pale-gray;
$text-color: $cool-gray-medium;

@import 'theme-base';
@include theme('unified');
