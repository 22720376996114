


$background-color: $sb-pale-gray-light;
$hover-background-color: $bank-light-gray-tertiary;
$border-color: $bank-light-gray-tertiary;
$text-color: $black;

@import 'theme-base';
@include theme('consumer-legacy');
