@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
[data-sparta-container] .#{$moduleNameSpace} {
  font-family: $roboto-family;
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);


  h2,
  h3,
  p,
  a,
  span,
  ul,
  ol,
  li,
  div,
  nav {
    font-family: inherit;
    font-size: inherit;
  }

  ol {
    margin-left: 1rem;
  }

  .browse-with-specialist {
    padding-left: rem-calc(0);
    line-height: rem-calc(18);
    margin-top: rem-calc(-1);
  }


  .squeezebox-wrapper {
    margin-bottom: 0;

    .squeezebox-panel {
      .panel-header {
        font-size: 16px;
      }

      .panel-content {
        background-color: transparent;
      }
    }
  }


  .legal-section__squeeze-panel {
    @media #{$xxlarge-up} {
      > .row {
        padding-left: $spacing-16;
        padding-right: $spacing-16;
      }
    }
  }



  .footer {
    &.global-footer-spacing--margin {
      margin-top: 50px;
    }
    &.global-footer-spacing--padding {
      margin-top: 0px;
      padding-top: 50px;
    }


    > :nth-last-child(2) {
      &.footer__actions .footer__actions-flex {
        border-bottom: 0;
        margin-bottom: -$spacing-24;
      }

      &.footer__links nav {
        border-bottom: 0;
        margin-bottom: -$spacing-20;
      }

      &.footer__breadcrumbs nav {
        border-bottom: 0;
        margin-bottom: -$spacing-24;
      }
    }


    &__actions-flex {
      align-items: center;
      border-bottom: 1px solid $color-gray-s20;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;

      .footer__actions--area {
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 0;
        padding-right: $spacing-24;
        padding-top: 9px;
        padding-bottom: 9px;
      }

      .footer__actions--language,
      .footer__actions--authentication {
        flex-basis: auto;
      }

      .footer__actions--language {
        margin-left: auto;


        [disabled] {
          &,
          &:active,
          &:hover,
          &:focus {
            color: $color-gray;
            cursor: default;
            text-decoration: none;
          }
        }
      }

      > div {
        padding-right: $spacing-16;

        &:last-child {
          padding-right: 0;
        }
      }

      .footer__actions-pipe {
        padding-left: $spacing-16;
        position: relative;

        &:before {
          background-color: $color-gray-s20;
          bottom: 0;
          content: ' ';
          height: 17px;
          left: 0;
          margin: auto;
          position: absolute;
          top: 0;
          width: 1px;
        }
      }

      .footer__actions--area {
        color: $color-black;
        font-size: rem-calc(16px);
        line-height: rem-calc(24px);
      }

      a {
        font-size: rem-calc(16px);
        line-height: rem-calc(24px);
      }
    }


    &__links {
      &--auth {
        nav {
          border-bottom: 1px solid $color-gray-s20;
          padding: $spacing-8 0;
        }

        ul {
          margin-bottom: $spacing-4;
        }
      }
    }



    &__list-has-pipes {
      ul {
        display: block;
        list-style: none;
        margin: 10px 0 0;
        text-align: center;
      }

      li {
        display: inline;
        line-height: 1;
        list-style-type: none;
        text-align: left;

        &:after {



          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMSIgaGVpZ2h0PSIzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGV4dCB4PSIxMDAlIiB5PSIxMDAlIiBkb21pbmFudC1iYXNlbGluZT0iYWxwaGFiZXRpYyIgdGV4dC1hbmNob3I9Im1pZGRsZSIgZmlsbD0iI2JhYmFiYSIgc3Ryb2tlPSIjZDVkNWQ1IiBmb250LXNpemU9IjMwIj58PC90ZXh0Pjwvc3ZnPg==');
          background-position: center;
          background-repeat: no-repeat;
          content: ' ';
          letter-spacing: rem-calc(32px);
        }

        > a {
          display: inline-block;
          margin-bottom: $spacing-8;
          white-space: nowrap;


                    &:hover,
          &:focus,
          &:active {

            text-decoration: underline;
          }
        }

          .spa-btn.spa-btn--link {

          text-decoration: none;
          font-size: 14px;
          white-space: nowrap;
          vertical-align: baseline;

            &:hover,
          &:focus,
          &:active {

            text-decoration: underline; 
          }

            &.spa-ui-layer-tooltip-link:not(.icon-link) {
            text-decoration: none;
            padding-bottom: 2px;
            border-bottom: 1px dotted;

                &:hover,
            &:focus {
              text-decoration: underline;
              border-bottom-color: transparent;
            }
          }

          &#footer_bofa_CCPA{
            @media #{$small-only} {
              text-align: center;
            }
          }
        }


        &.mda-language-toggle {
          display: none;
        }
      }
    }


    &__breadcrumbs {
      nav {
        border-bottom: 1px solid $color-gray-s20;
        margin: 0;
        padding: 0;
      }

      ol {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        display: flex;
        margin: 0;
        padding: 0 0 $spacing-12;

        &:first-child {
          padding-top: $spacing-12;
        }

        &:before,
        &:after {
          border-style: solid;
          border-width: 2px 2px 0 0;
          content: '';
          display: none;
          height: 9px;
          margin: 0 10px 0 8px;
          position: relative;
          top: 5px;
          transform: scale(0.55) rotate(45deg);
          width: 9px;
        }

        @media #{$small-only} {
          flex-basis: 100%;

          &:before {
            display: inline-block;
            margin-left: 0;
          }
        }

        @media #{$medium-up} {
          padding-top: $spacing-12;

          &:after {
            display: inline-block;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }
    }


    &__power-list {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      width: 95%;
      margin: 0 auto;

            @media #{$medium-up},#{$large-up} {
        width: 100%;
      }
      @media #{$medium-up} {
        flex-flow: row nowrap;
        margin-top: $spacing-24;
        padding: 0 $spacing-16;

        nav {
          margin-right: $spacing-8;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      @media #{$large-up} {
        padding: 0 $spacing-24;
      }

      h3 {
        color: $color-black;
        margin-bottom: 0;
        margin-top: 0;

        @media #{$medium-up} {
          margin-bottom: $spacing-12;
        }

        a {
          align-items: center;
          display: flex;
          width: 100%;

          @media #{$medium-up} {
            margin-left: -5px; 
          }
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }

      li {
        margin: 0;
        padding: 0 0 $spacing-12 0;

        &:last-child {
          padding-bottom: 0;
        }
      }

      a {
        line-height: rem-calc(16px);
      }
    }


    &__bottom {
      margin-top: $spacing-24;

      .equalhousing {
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: 15px 12px;
        background-position: right 5px;
        display: inline-block;
        padding-right: 20px;
      }

      .footer__legal-text{
        margin-bottom: 0;
      }

      .footer__copyright-text{
        margin-bottom: 0;
      }
      .footer-last {
        margin-bottom: $spacing-16;
        @media #{$large-up} , #{$medium-up}  {
            margin-top: 13px;
        } 
      }

      &.footer__bottom--unauth {
        background-color: $color-brand-secondary;
        color: $white;
        a {
          color: $white;
          text-decoration: none;

          &:hover,
          &:focus,
          &:active {
            color: $color-brand-secondary-s20;
            text-decoration: underline; 
          }



        }
        .spa-btn.spa-btn--link {
          color: $white;
          text-decoration: none;
          font-size: 14px;

          &:hover,
          &:focus,
          &:active {
            color: $color-brand-secondary-s20;
            text-decoration: underline; 
          }
          &#footer_bofa_advetising_practices{

            text-decoration: none;
          }
        }
        #advertisingPracticesLayer{
          a{
            text-decoration: underline;

                        &:hover, &:focus, &:active {
              text-decoration: none;
            }
          }
        }
        .footer__legal-text,.footer__patent-text{
          a{
            text-decoration: underline;
            &#equalHousingLender:focus,&#footerPatent:focus {
              border: 1px solid #ccd3e1;
              border-radius: 2px;
            }
        }
          a:hover{
            text-decoration: none;
          }
        }
        .footer__list-has-pipes {
          li {
            padding: 0;

            &:after {
              background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMSIgaGVpZ2h0PSIzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGV4dCB4PSIxMDAlIiB5PSIxMDAlIiBkb21pbmFudC1iYXNlbGluZT0iYWxwaGFiZXRpYyIgdGV4dC1hbmNob3I9Im1pZGRsZSIgZmlsbD0iI2ZmZmZmZiIgc3Ryb2tlPSIjZmZmZmZmIiBmb250LXNpemU9IjMwIj58PC90ZXh0Pjwvc3ZnPg==');
            }
          }
        }
      }

      &.footer__bottom--auth {
        .text-center {
          border-top: 1px solid $color-gray-s20;
          padding-top: $spacing-24;
        }
      }
    }

    .footer__links {
      a {
        &#footer_bofa_CCPA{
          display: inline-block;
          @media #{$small-only} {
            @media (orientation: portrait){
              display: inline;
            }
          }
        }
      }

      #pillImage{
        max-width: 100%;
        height: 12px;
        width: auto;
        vertical-align: middle;
        margin-bottom: 3px;
        margin-left: 5px;
        display: none;
      }
    }
  }


  [class*='-section__panel'] {
    padding-top: $spacing-16;
    width: 99%;
    margin: 0 auto;

    &:first-child {
      border-bottom: 1px solid $color-brand-tertiary;
    }

    &:not(:first-child) {
      > .row {
        > .column {
          > :last-child {
            border-bottom: 1px solid $color-gray-s20;
          }
        }
      }
    }
  }


  [class*='-section__footnotes'] {
    &:empty {
      display: none;
    }

    li {
      margin-bottom: $spacing-16;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p,
    a {
      margin: 0;
      padding: 0;
    }
  }


  .legal-section {

    &__panel {
      h3,
      [class*='section__sub-header'] {
        font-size: rem-calc(16px);
      }

      a {
        text-decoration: underline;

                &:hover, &:focus, &:active {
          text-decoration: none;
        }
      }

       div {
        .columns > div {
          &:last-child {
            border-bottom: $spacing-1 solid $color-gray-s20;
            padding-bottom: $spacing-16;

            p {
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        > p {
          margin-bottom: $spacing-16;
        }
      }


      .spa-cms-wrapper {
        margin-bottom: $spacing-16;
      }
    }

    &__custom-disclosure {
      ul {
        list-style: decimal;
      }
    }


    &__invest-section {
      border-top: 1px solid $color-gray-s20;
      margin-top: $spacing-16;
      padding-top: $spacing-16;

      li {
        margin-bottom: 0;
        padding-left: 0;
      }
    }

    &__invest-title {
      font-weight: bold;
      margin-bottom: $spacing-8;
    }

    &__invest-boxes {
      border: $spacing-1 solid;
      border-bottom: 0;
      border-left: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      font-weight: bold;
      list-style: none;
      margin: 0 0 $spacing-20;
      padding: 0;

      @media #{$medium-up} {
        flex-direction: row;
      }

      li {
        border-bottom: $spacing-1 solid;
        border-left: $spacing-1 solid;
        display: flex;
        flex-basis: 31%; 
        flex-direction: column;
        flex-grow: 1;
        justify-content: center;
        margin: 0;
        min-height: 2em; 
        padding: 9px $spacing-10;
        text-align: center;
        font-size: 16px;
        min-height: 76px;
      }
    }

    &__invest-text {
      margin-top: $spacing-16;
    }
  }


  [data-module='global-social-module'] {
    margin: $spacing-24 auto;

    .boa-social--header {
      font-weight: bold;
      font-size: rem-calc(16px);
    }

    .boa-social--follow {
      align-items: center;
      justify-content: center;
    }

    .boa-social-size--small {
      a:first-of-type {
        margin-left: 0;
      }
    }
  }



  @media screen and (min-width: 768px) {
    a[href^='tel:'] {
      pointer-events: none;
      text-decoration: none;
      color: #222;
      font-weight: normal;
    }
  }


  @import 'unified';
  @import 'consumer';
  @import 'consumer-legacy';
  @import 'smallbusiness';
}
